@use "../mixins-breakpoints";

.ax-card {
  border-radius: var(--border-radius-12);
  border: var(--ax-border-style);
  box-shadow: var(--shadow-card);
  padding: var(--space-24);
  background: var(--color-neutral-white);
  position: relative;
  overflow: hidden;

  &.no-padding {
    padding: 0;
  }

  &.padding-narrow {
    padding: var(--space-12);
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.fit-content {
    width: fit-content;
  }

  &.show-overflow {
    @include mixins-breakpoints.breakpoint-desktop-large {
      overflow: visible;
    }
  }

  &.form {
    padding-bottom: 0;
  }

  .inline-padding {
    padding: var(--space-24);

    &--narrow {
      padding: var(--space-16) var(--space-24);
    }
  }

  .table,
  &.table {
    overflow-y: hidden;
  }

  &--success {
    &:before {
      display: block;
      content: '';
      height: 8px;
      width: 100%;
      background-color: var(--color-success-state-background-solid);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: calc(var(--border-radius-8) - 1px) calc(var(--border-radius-8) - 1px) 0 0;
    }

    h3 {
      color: var(--color-text-success);
    }
  }

  &--warning {
    &:before {
      display: block;
      content: '';
      height: 8px;
      width: 100%;
      background: var(--color-warning-state-background-solid);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: calc(var(--border-radius-8) - 1px) calc(var(--border-radius-8) - 1px) 0 0;
    }

    h3 {
      color: var(--color-text-warning);
    }
  }

  &--error {
    &:before {
      display: block;
      content: '';
      height: 8px;
      width: 100%;
      background-color: var(--color-error-state-background-solid);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: calc(var(--border-radius-8) - 1px) calc(var(--border-radius-8) - 1px) 0 0;
    }

    h3 {
      color: var(--color-text-error);
    }
  }

  > :first-child {
    border-top-left-radius: var(--border-radius-8);
    border-top-right-radius: var(--border-radius-8);
  }

  > :last-child {
    border-bottom-left-radius: var(--border-radius-8);
    border-bottom-right-radius: var(--border-radius-8);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-16);
    margin-bottom: var(--space-16);
  }

  &--with-image {
    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(1.05);
          transform-origin: center;
        }
      }
    }
  }

  &__header-with-options {
    display: flex;
    gap: var(--space-16);
    margin-bottom: var(--space-16);
    align-items: center;
    justify-content: space-between;


    &.column--mobile {
      flex-direction: column;
      align-items: start;

      @include mixins-breakpoints.breakpoint-tablet {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-16) !important;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    @include mixins-breakpoints.breakpoint-tablet {
      aspect-ratio: 3 / 2;
    }

    img {
      transition: all 70ms ease-in;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--is-news {
      aspect-ratio: 21 / 9;
    }
  }

  &__edit-button {
    position: absolute;
    top: var(--space-16);
    right: var(--space-24);
    z-index: 1;
  }

  &-sections {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: auto;
      padding: var(--space-24);
      border-right: 1px solid var(--color-neutral-200);
      border-top: 1px solid var(--color-neutral-200);

      &:last-child {
        border-right: none;
      }
    }
  }
}
