.ax-list {
  li {
    padding: var(--space-12) var(--space-24);
    white-space: nowrap;

    &:not(.ax-list__sublist) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-12);
    }

    &.wrap {
      white-space: normal;
    }

    &.align-start {
      justify-content: flex-start;
    }

    &:not(:last-child) {
      border-bottom: solid 1px var(--color-neutral-200);
    }

    &.no-inner-padding {
      padding: var(--space-12) 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &.no-border {
      border: none;
    }

    &.disabled {
      opacity: 0.5;
    }

    &.hoverable {
      @media (hover: hover) {
        &:hover {
          background: var(--color-primary-50);
        }
      }
    }

    &.ax-list__sublist {
      p {
        padding: var(--space-12) var(--space-24);
      }
    }
  }

  &-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);
  }

  &.no-flex {
    li {
      display: block;
    }
  }

  &.standalone {
    li {
      border-radius: var(--border-radius-8);
      border: none;

      &:not(:last-of-type) {
        margin-bottom: var(--space-8);
      }
    }
  }

  &--padding-24 {
    li {
      padding: var(--space-24);
    }
  }

  &--padding-12 {
    li {
      padding: var(--space-12);
    }
  }
}
