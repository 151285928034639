// Node Modules & Libraries
@use "node_modules/reset.css";
@use "@angular/cdk/overlay-prebuilt.css";

// Theme
@use "variables";
@use "mixins-breakpoints";
@use "sizing-helpers";
@use "action-element";
@use "animations";
@use "flex-helpers";
@use "fonts";
@use "grid";
@use "typography";

// Component Bases
@use "components/buttons";
@use "components/button-group";
@use "components/badge";
@use "components/button";
@use "components/card";
@use "components/description-details-list";
@use "components/dialog-overlay";
@use "components/divider";
@use "components/icon";
@use "components/info-label";
@use "components/input";
@use "components/link";
@use "components/label-badge";
@use "components/list";
@use "components/loading-element";
@use "components/navigation-list";
@use "components/qrcode";
@use "components/search";
@use "components/table";
@use "components/table-filters";


html, input, textarea, button {
  font-family: var(--font-regular);
  color: var(--color-text-primary);
}

// set focus to light green outline for all elements
*:focus, *:focus-visible {
  outline-color: var(--color-primary-300);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.display-block {
  display: block;
}

.hidden {
  opacity: 0;
}

* {
  box-sizing: border-box;
  font-feature-settings: 'tnum';
}
