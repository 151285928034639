@use "../mixins-breakpoints";

.ax-info-label {
  padding: var(--space-12);
  background: var(--color-primary-100);
  border-radius: var(--border-radius-12);

  p, small:not(ax-ui-button small) {
    color: var(--color-text-secondary);
  }

  h1, h2, h3, h4, h5 {
    color: var(--color-primary-600);
    margin-top: var(--space-8);
  }

  &-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    gap: var(--space-24);
    width: fit-content;

    & > div {
      min-width: 100px;
    }

    @include mixins-breakpoints.breakpoint-tablet {
      grid-auto-flow: column;
    }

    &.flex {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &.color--neutral {
    background: var(--color-neutral-state-background-solid);

    h1, h2, h3, h4, h5, small {
      color: var(--color-text-secondary);
    }
  }

  &.color--warning {
    background: var(--color-warning-state-background-solid);

    h1, h2, h3, h4, h5, small {
      color: var(--color-text-warning);
    }
  }

  &.color--error {
    background: var(--color-error-state-background-solid);

    h1, h2, h3, h4, h5, small {
      color: var(--color-text-error);
    }
  }
}
