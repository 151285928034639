.ax-table {
  width: 100%;
  min-height: 110px;
  position: relative;

  &-loading {
    display: flex;
    justify-content: center;
    background-color: var(--color-neutral-white-opacity-50);
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    ax-ui-loading-spinner {
      top: 58px;
      position: inherit;
      display: block;
      width: var(--space-48);
      height: var(--space-48);
    }
  }

  &-image {
    width: 120px;
    height: 64px;
    border-radius: var(--border-radius-8);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-wrapper {
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      table-layout: auto;
      border-collapse: collapse;

      &.table-layout-fixed {
        table-layout: fixed;
      }

      caption {
        text-align: left;
        margin: var(--space-24);

        &.hide {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }

      tbody {
        width: 100%;

        tr:last-of-type {
          th, td {
            border-bottom: none;
          }
        }
      }

      th, td {
        border: solid var(--color-border);
        border-width: 0 0 1px 0;
        text-align: left;
        vertical-align: middle;

        &.td-padding {
          padding: var(--space-16) var(--space-24);

          &--narrow {
            padding: var(--space-12) var(--space-24);
          }
        }

        &.td-vertical-align-top {
          vertical-align: top;
        }

        &.td-hover:hover {
          background-color: var(--color-primary-100);
          cursor: pointer;
        }

        &.td-flex-end {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        &.td-text-align-right {
          text-align: right;
        }

        &.td-text-align-center {
          text-align: center;
        }

        &.td-fix-width-500 {
          width: 500px;
          min-width: 500px;
        }

        &:not(:first-of-type).border-left {
          border-left: var(--ax-border-style);
        }

        &:not(:last-of-type).border-right {
          border-right: var(--ax-border-style);
        }

        &:not(:last-of-type).border-right-fat {
          border-right: var(--ax-border-style-fat);
        }
      }

      tfoot {
        th {
          border-width: 1px 0 0 0;
        }
      }

      th {
        font-family: var(--font-bold);
        color: var(--color-text-secondary);
        background-color: var(--color-neutral-grey-accent);
        padding: var(--space-16) var(--space-24);
        white-space: nowrap;
      }

      td {

        &:first-child {
          padding-left: var(--space-24);
        }

        &:last-child {
          padding-right: var(--space-24);

          .ax-buttons, ax-ui-button {
            display: flex;
            justify-content: flex-end;
          }
        }

        ax-ui-checkbox {
          display: block;
          margin-left: var(--space-12);
        }

        &.even-odd {
          background-color: var(--color-neutral-grey-accent);
        }
      }

      tr {
        position: relative;

        &.even-odd {
          background-color: var(--color-neutral-grey-accent);
        }

        &.even-odd-1 {
          background-color: var(--color-primary-50);
        }

        &.even-odd-2 {
          background-color: var(--color-primary-100);
        }

        &:not(.active, .disabled):hover {
          background-color: var(--color-primary-50);
        }

        &[ng-reflect-router-link]:hover {
          cursor: pointer;
          background-color: var(--color-primary-50);
        }

        &.indent-1 {
          td:first-of-type {
            padding-left: var(--space-40);
          }
        }

        &.indent-2 {
          td:first-of-type {
            padding-left: var(--space-56);
          }
        }

        &.disabled {
          opacity: var(--opacity-disabled);
          background: var(--color-neutral-100);
        }

        &.inactive {
          background: var(--color-neutral-50);
        }
      }
    }
  }

  .ax-ui-table-header {

    &.row {
      border-width: 0 1px 1px 0;
      width: 220px;
    }

    &.small {
      padding: var(--space-4) var(--space-24);

      .ax-ui-table-header-content {
        font-size: clamp(0.82rem, 0.8073rem + 0.0679vw, 0.8888rem);
        white-space: normal;
        min-width: 64px;
        max-height: 54px;
      }
    }

    &-content {
      user-select: none;
      display: flex;
      align-items: center;
      gap: 4px;
      line-height: 130%;

      &.sortable {
        &:hover {
          cursor: pointer;
        }
      }

      &.align-right {
        justify-content: flex-end;
      }
    }

    .sort-active {
      color: var(--color-primary);
    }
  }
}
