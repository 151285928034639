@use "mixins-breakpoints";

/*
 * Custom Flexbox Helper Classes
 */
.flex {
  display: flex;

  &-1 {
    flex: 1;
  }

  &.equal-sizing {
    > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.width-50 {
    width: 100%;

    > * {
      width: 50%;
    }

    &--mobile-large {
      @include mixins-breakpoints.breakpoint-mobile-large {
        > * {
          width: 50%;
        }
      }
    }

    &--desktop {
      @include mixins-breakpoints.breakpoint-desktop {
        > * {
          width: 50%;
        }
      }
    }
  }

  &.gap-0 {
    gap: 0;
  }

  &.gap-4 {
    gap: 4px;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-12 {
    gap: 12px;
  }

  &.gap-16 {
    gap: 16px;
  }

  &.gap-24 {
    gap: 24px;
  }

  &.gap-32 {
    gap: 32px;
  }

  &.gap-40 {
    gap: 40px;
  }

  &.gap-48 {
    gap: 48px;
  }

  &.gap-56 {
    gap: 56px;
  }

  &.gap-64 {
    gap: 64px;
  }

  &.gap-72 {
    gap: 72px;

    &--responsive {
      gap: 40px;

      @include mixins-breakpoints.breakpoint-desktop {
        gap: 72px;
      }
    }
  }

  &.gap-120 {
    gap: 120px;
  }

  &.gap-5vw {
    gap: 5vw;
  }


  &.flex-column {
    flex-direction: column;

    &-reverse {
      flex-direction: column-reverse;
    }

    &.top {
      align-content: flex-start;
    }

    &.bottom {
      align-content: flex-end;
    }

    &.vertical-center {
      align-content: center;
    }

    &.space-between {
      align-content: space-between;
    }

    &.space-around {
      align-content: space-around;
    }

    &.start {
      align-items: start;
    }

    &.end {
      align-items: end;
    }

    &.center {
      align-items: center;
    }

    &--desktop {
      @include mixins-breakpoints.breakpoint-desktop {
        flex-direction: column;
      }
    }
  }

  // end column

  &.flex-row {
    flex-direction: row;

    &.wrap {
      flex-flow: row wrap;
    }

    &.wrap-reverse {
      flex-flow: row wrap-reverse;
    }

    &.top {
      align-items: flex-start;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.vertical-center {
      align-items: center;
    }

    &.baseline {
      align-items: baseline;
    }

    &.stretch {
      align-items: stretch;
    }

    &--mobile-large {
      @include mixins-breakpoints.breakpoint-mobile-large {
        flex-direction: row;
      }
    }

    &--desktop {
      @include mixins-breakpoints.breakpoint-desktop {
        flex-direction: row;
      }
    }

    &-reverse {
      flex-direction: row-reverse;

      &--desktop {
        @include mixins-breakpoints.breakpoint-desktop {
          flex-direction: row-reverse;
        }
      }
    }
  }

  // end row

  // along main axis
  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: center;
  }

  &.mobile {
    &__column {
      flex-direction: column;

      @include mixins-breakpoints.breakpoint-tablet {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &.tablet {
    &__column {
      flex-direction: column;

      @include mixins-breakpoints.breakpoint-desktop {
        flex-direction: row;
      }
    }
  }

  &.mobile__column,
  &.tablet__column {
    &.equal-sizing {
      @include mixins-breakpoints.breakpoint-tablet-max {
        > * {
          flex-basis: unset;
        }
      }
    }
  }

  &.desktop {
    &--align-center {
      @include mixins-breakpoints.breakpoint-desktop-large {
        align-items: center;
      }
    }
  }
}

.no-wrap {
  flex-wrap: nowrap !important;
}

// end flexbox helpers
