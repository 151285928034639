.ax-input {
  --input-border-color: var(--color-neutral-300);

  display: flex;
  flex-direction: column;
  position: relative;

  input, textarea, select, .ax-ui-select__custom, trix-editor {
    box-sizing: border-box;
    line-height: 100%;
    gap: 10px;
    border-radius: var(--border-radius-8);
    border: 1px solid var(--input-border-color);
    background: var(--color-background);
    box-shadow: var(--shadow-input);

    &:not(textarea, trix-editor) {
      height: 42px;
    }

    &::placeholder, .placeholder {
      color: var(--color-neutral-500);
      opacity: var(--opacity-80);
    }

    &:not(trix-editor) {
      padding: 10px var(--space-16);
      display: flex;
      align-items: center;
      align-self: stretch;
      transition: border var(--animation-form-edit-transformation-duration) ease-in-out, border-radius var(--animation-form-edit-transformation-duration) ease-in-out, padding var(--animation-form-edit-transformation-duration) ease-in-out;

      &:not([readonly], .readonly) {
        &:focus, &.focused {
          outline: 2px solid var(--color-primary-200);
        }
      }
    }

    &[disabled], &.disabled {
      pointer-events: none;
      background: var(--color-neutral-50);
      box-shadow: none;
    }

    &[readonly], &.readonly {
      outline: none;
      box-shadow: none;
      border-radius: 0;
      border-color: transparent transparent var(--color-neutral-200) transparent;
      padding-left: 0;

      &.readonly--no-borders {
        border-color: transparent;
      }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  input {
    &[type="search"] {
      -moz-appearance: textfield;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: none;
      }
    }
  }

  textarea {
    resize: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  label, input, textarea, select {
    margin-bottom: 2px;
  }

  &__hint {
    &.hide {
      height: var(--space-12);
    }
  }

  &__flexbox {
    container-type: inline-size; /* This enables container queries */
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--space-24);

    & > * {
      flex: 0 0 calc(50% - var(--space-16));
      min-width: 200px;
    }

    .columns-1 {
      @container (min-width: 664px) {
        flex: 0 0 calc(33.333% - 21px);
      }
    }

    .columns-2 {
      @container (min-width: 664px) {
        flex: 0 0 calc(66.666% - 11px);
      }
    }

    &.rows-3 {
      @container (min-width: 664px) {
        & > * {
          flex: 0 0 calc(33.333% - 21.5px);
        }
      }
    }

    @container (max-width: 440px) {
      & > * {
        flex: 0 0 100% !important;
      }
    }
  }

  &.icon {
    input, textarea, .ax-ui-select__custom {
      padding-left: var(--space-40);
    }

    ax-ui-icon {
      color: var(--color-neutral-500);

      &:not(.arrow-down, .ax-loading-element) {
        position: absolute;
        top: var(--space-8);
        left: var(--space-8);
      }
    }

    &.icon-right {
      input, textarea {
        padding-left: var(--space-16);
      }

      ax-ui-icon:not(.ax-loading-element) {
        left: initial;
        right: var(--space-12);
      }
    }
  }

  &.error {
    input, textarea, .ax-ui-select__custom {
      border: 1px solid var(--color-error-state-outline);

      &:focus {
        outline: 2px solid var(--color-error-state-outline);
      }
    }

    .ax-input__hint b {
      color: var(--color-text-error);
    }
  }

  &.table-input {

    input, textarea, .ax-ui-select__custom, select {
      margin: 0;
    }

    input {
      width: 100%;
    }

    .ax-input__hint {
      display: none;
    }

    &:not(.editable) {

      input, textarea {
        pointer-events: none;
        border: none;
        box-shadow: none;
        background: transparent;
      }
    }
  }
}

label {
  display: block;
}

input[type="range"] {
  accent-color: var(--color-primary-600);
}
