.ax-action-element {
  transition: all 0.15s ease-in-out;

  * {
    text-decoration: none !important;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background: var(--color-primary-50);
      color: inherit;
    }

    *:not(.color-light, .ax-ui-infoblock__icon):hover {
      cursor: pointer;
      color: inherit;
    }
  }

  &:active {
    background: var(--color-primary-100);
    transition: 0s;
    color: inherit;
  }

  &:focus-visible {
    background: var(--color-primary-50);
  }

  &.active {
    background: var(--color-primary-100);
    font-family: var(--font-medium);

    .color-light {
      font-family: var(--font-regular);
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      background: inherit;
      color: inherit;
    }
  }

  a {
    display: flex;
    gap: var(--space-8);
    align-items: center;
    color: var(--color-neutral-800);
  }
}
