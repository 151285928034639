:root {
  /* Color styles */
  --color-primary: var(--color-primary-500);
  --color-background: rgb(251, 254, 251);

  --color-primary-50: rgb(242, 250, 240);
  --color-primary-100: rgb(225, 243, 221);
  --color-primary-200: rgb(196, 231, 187);
  --color-primary-300: rgb(159, 217, 145);
  --color-primary-400: rgb(113, 199, 92);
  --color-primary-500: rgb(81, 171, 59);
  --color-primary-600: rgb(74, 155, 54);
  --color-primary-700: rgb(65, 137, 47);
  --color-primary-800: rgb(52, 110, 38);
  --color-primary-900: rgb(38, 80, 27);
  --color-primary-950: rgb(25, 53, 18);

  // Neutral
  --color-neutral-black: rgb(0, 0, 0);
  --color-neutral-white: rgb(255, 255, 255);
  --color-neutral-white-opacity-50: rgba(255, 255, 255, 0.5);
  --color-neutral-grey-accent: var(--color-neutral-25);

  --color-neutral-25: rgb(252, 252, 253);
  --color-neutral-50: rgb(249, 250, 251);
  --color-neutral-100: rgb(242, 244, 247);
  --color-neutral-200: rgb(228, 231, 236);
  --color-neutral-300: rgb(208, 213, 221);
  --color-neutral-400: rgb(152, 162, 179);
  --color-neutral-500: rgb(102, 112, 133);
  --color-neutral-600: rgb(71, 84, 103);
  --color-neutral-700: rgb(52, 64, 84);
  --color-neutral-800: rgb(24, 34, 48);
  --color-neutral-900: rgb(16, 24, 40);
  --color-neutral-950: rgb(12, 17, 29);

  --color-error-25: rgb(255, 251, 250);
  --color-error-50: rgb(254, 243, 242);
  --color-error-100: rgb(254, 228, 226);
  --color-error-200: rgb(254, 205, 202);
  --color-error-300: rgb(253, 162, 155);
  --color-error-400: rgb(249, 112, 102);
  --color-error-500: rgb(240, 68, 56);
  --color-error-600: rgb(217, 45, 32);
  --color-error-700: rgb(180, 35, 24);
  --color-error-800: rgb(145, 32, 24);
  --color-error-900: rgb(122, 39, 26);
  --color-error-950: rgb(85, 22, 12);

  --color-warning-25: rgb(255, 252, 245);
  --color-warning-50: rgb(255, 250, 235);
  --color-warning-100: rgb(254, 240, 199);
  --color-warning-200: rgb(254, 223, 137);
  --color-warning-300: rgb(254, 200, 75);
  --color-warning-400: rgb(253, 176, 34);
  --color-warning-500: rgb(247, 144, 9);
  --color-warning-600: rgb(220, 104, 3);
  --color-warning-700: rgb(181, 71, 8);
  --color-warning-800: rgb(147, 55, 13);
  --color-warning-900: rgb(122, 46, 14);
  --color-warning-950: rgb(78, 29, 9);

  // Info States
  --color-neutral-state-background-solid: var(--color-neutral-200); // only use if no border and no content inside
  // style for container with content inside
  --color-neutral-state-background: var(--color-neutral-50);
  --color-neutral-state-content: var(--color-neutral-700); // use with colored background
  --color-neutral-state-border: var(--color-neutral-200);

  --color-success-state-background-solid: var(--color-primary-200); // only use if no border and no content inside
  // style for container with content inside
  --color-success-state-background: var(--color-primary-50);
  --color-success-state-content: var(--color-primary-700); // use with colored background
  --color-success-state-border: var(--color-primary-200);
  --color-text-success: var(--color-primary-600); // use with neutral background

  --color-warning-state-background-solid: var(--color-warning-200); // only use if no border and no content inside
  // style for container with content inside
  --color-warning-state-background: var(--color-warning-50);
  --color-warning-state-content: var(--color-warning-700); // use with colored background
  --color-warning-state-border: var(--color-warning-200);
  --color-text-warning: var(--color-warning-600); // use with neutral background

  --color-error-state-background-solid: var(--color-error-200); // only use if no border and no content inside
  // style for container with content inside
  --color-error-state-background: var(--color-error-50);
  --color-error-state-content: var(--color-error-700); // use with colored background
  --color-error-state-border: var(--color-error-200);
  --color-text-error: var(--color-error-600); // use with neutral background
  --color-error-state-outline: var(--color-error-500); // for borders, e.g. inputs

  --color-info-states-neutral-light: var(--color-neutral-50);
  --color-info-states-neutral-medium: var(--color-neutral-200);
  --color-info-states-neutral: var(--color-neutral-500);
  --color-info-states-neutral-dark: var(--color-neutral-700);

  --color-notifications-background: var(--color-error-500);

  /* Gradients */
  --color-gradient-ax-green: linear-gradient(148deg, var(--color-primary-300) 0.08%, var(--color-primary) 100.06%);
  --color-gradient-ax-green-light: linear-gradient(to left top, #ddf2d5, #e2f4db, #e7f6e0, #ebf7e6, #f0f9ec);
  --color-gradient-sun-bright: linear-gradient(148deg, #F7FFD5 0.08%, #6FC358 100.06%);
  --color-gradient-radial-green-blur: radial-gradient(50% 50% at 50% 50%, #D5F0CD 0%, rgba(239, 252, 211, 0.00) 100%);;

  /* Fonts */
  --font-regular: 'Gotham Narrow Regular', Tahoma, Verdana, sans-serif;
  --font-medium: 'Gotham Narrow Medium', Tahoma, Verdana, sans-serif;
  --font-bold: 'Gotham Narrow Bold', Tahoma, Verdana, sans-serif;

  --color-text-primary: var(--color-neutral-900);
  --color-text-secondary: var(--color-neutral-600);
  --color-text-disabled: var(--color-neutral-500);

  /* shadows */
  // colors shadow
  --colors-shadow-xs: rgb(16 24 40 / 0.05);
  --colors-shadow-sm-01: rgb(16 24 40 / 0.1);
  --colors-shadow-sm-02: rgb(16 24 40 / 0.06);
  --colors-shadow-md-01: rgb(16 24 40 / 0.1);
  --colors-shadow-md-02: rgb(16 24 40 / 0.06);
  --colors-shadow-lg-01: rgb(16 24 40 / 0.08);
  --colors-shadow-lg-02: rgb(16 24 40 / 0.03);
  --colors-shadow-xl-01: rgb(16 24 40 / 0.08);
  --colors-shadow-xl-02: rgb(16 24 40 / 0.03);
  --colors-shadow-2xl: rgb(16 24 40 / 0.18);
  --colors-shadow-3xl: rgb(16 24 40 / 0.14);

  // shadows
  --shadow-xs: 0px 1px 2px 0px var(--colors-shadow-xs);
  --shadow-sm: 0px 1px 3px 0px var(--colors-shadow-sm-01), 0px 1px 2px 0px var(--colors-shadow-sm-02);
  --shadow-md: 0px 4px 8px -2px var(--colors-shadow-md-01), 0px 2px 4px -2px var(--colors-shadow-md-02);
  --shadow-lg: 0px 12px 16px -4px var(--colors-shadow-lg-01), 0px 4px 6px -2px var(--colors-shadow-lg-02);
  --shadow-xl: 0px 20px 24px -4px var(--colors-shadow-xl-01), 0px 8px 8px -4px var(--colors-shadow-xl-02);
  --shadow-2xl: 0px 24px 48px -12px var(--colors-shadow-2xl);
  --shadow-3xl: 0px 32px 64px -12px var(--colors-shadow-3xl);

  --shadow-input: var(--shadow-xs);
  --shadow-button: var(--shadow-xs);
  --shadow-card: var(--shadow-xs);
  --shadow-card-hover: var(--shadow-lg);
  --shadow-modal: var(--shadow-xl);

  /* Overlay Styles */
  --overlay-background-transparency: rgba(var(--neutral-dark), 1);
  --overlay-blur: blur(25px);

  /* Opacities */
  --opacity-100: 1; /* 100% */
  --opacity-80: 0.8; /* 80% */
  --opacity-64: 0.64; /* 64% */
  --opacity-56: 0.56; /* 56% */
  --opacity-40: 0.4; /* 40% */
  --opacity-24: 0.24; /* 24% */
  --opacity-16: 0.16; /* 16% */
  --opacity-8: 0.08; /* 8% */
  --opacity-4: 0.04; /* 4% */
  --opacity-disabled: var(--opacity-64);

  /* spacings, see _sizing-helpers.scss for utilities */
  --space-4: 4px;
  --space-8: 8px;
  --space-12: 12px;
  --space-16: 16px;
  --space-24: 24px;
  --space-32: 32px;
  --space-40: 40px;
  --space-48: 48px;
  --space-56: 56px;
  --space-64: 64px;
  --space-72: 72px;
  --space-120: 120px;

  --border-radius-8: 8px;
  --border-radius-12: 12px;
  --border-radius-16: 16px;
  --border-radius-24: 24px;
  --border-radius-32: 32px;
  --border-radius-40: 40px;
  --border-radius-48: 48px;

  --color-border: var(--color-neutral-200);
  --ax-border-style: 1px solid var(--color-neutral-200);
  --ax-border-style-fat: 3px solid var(--color-neutral-300);

  /* breakpoints, see _mixins-breakpoints.scss for utilities */
  --mobile-small: 320px;
  --mobile-large: 640px;
  --tablet: 768px;
  --desktop: 1024px;
  --desktop-large: 1280px;
  --desktop-extra-large: 1440px;
  --desktop-super-large: 1920px;
  --desktop-wide-screen: 2560px;

  /* container max-widths, see _sizing-helpers.scss for utilities */
  --width-320: 320px;
  --width-384: 384px;
  --width-480: 480px;
  --width-560: 560px;
  --width-640: 640px;
  --width-768: 768px;
  --width-896: 896px;
  --width-1024: 1024px;
  --width-1280: 1280px;
  --width-1440: 1440px;
  --width-1600: 1600px;
  --width-1920: 1920px;

  /* z-indexes */
  --z-index-floating: 100;
  --z-index-modal: 105;
  --z-index-dialogs: 110;

  --input-max-width: 600px;

  /* animations */
  --animation-translate-in-out-duration: 150ms;
  --animation-form-edit-transformation-duration: 100ms
}

/* Color theme for test mode */
[theme-test-mode] {
  --color-primary-50: rgb(240, 247, 255);
  --color-primary-100: rgb(225, 239, 254);
  --color-primary-200: rgb(195, 223, 254);
  --color-primary-300: rgb(155, 202, 253);
  --color-primary-400: rgb(111, 179, 252);
  --color-primary-500: rgb(46, 144, 250);
  --color-primary-600: rgb(11, 126, 249);
  --color-primary-700: rgb(5, 109, 219);
  --color-primary-800: rgb(4, 91, 184);
  --color-primary-900: rgb(3, 62, 125);
  --color-primary-950: rgb(2, 47, 95);
}

:root[data-theme="dark"] {
  --color-primary: rgb(107, 196, 84);
  --color-background: rgb(11, 25, 8);

  --color-primary-50: rgb(9, 19, 7);
  --color-primary-100: rgb(18, 38, 13);
  --color-primary-200: rgb(36, 76, 26);
  --color-primary-300: rgb(54, 114, 39);
  --color-primary-400: rgb(72, 152, 52);
  --color-primary-500: rgb(90, 190, 65);
  --color-primary-600: rgb(123, 203, 103);
  --color-primary-700: rgb(156, 216, 141);
  --color-primary-800: rgb(189, 229, 179);
  --color-primary-900: rgb(222, 242, 217);
  --color-primary-950: rgb(239, 248, 236);
}
