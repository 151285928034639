@use "../mixins-breakpoints";

@mixin dl-container-breakpoint {
  @container (max-width: 480px) {
    @content
  }
}

.ax-description-details-list {
  container-type: inline-size;

  dl {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;

    dd {
      &:not(:last-of-type) {
        border-bottom: solid 1px var(--color-neutral-200);
        margin-bottom: var(--space-16);
        padding-bottom: var(--space-16);
      }

      &.avatar {
        padding-left: 63px;
      }
    }
  }

  &:not(.column) {
    dl {
      @include mixins-breakpoints.breakpoint-tablet {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-row-gap: var(--space-16);
      }

      @include dl-container-breakpoint {
        display: flex;
        flex-direction: column;
        gap: 0;
      }

      dt {
        padding-right: var(--space-32);
        max-width: 220px;

        @include mixins-breakpoints.breakpoint-tablet {
          &:not(:last-of-type) {
            border-bottom: solid 1px var(--color-neutral-200);
            padding-bottom: var(--space-16);
          }
        }

        @include dl-container-breakpoint {
          padding-bottom: 0 !important;
          border-bottom: none !important;
          padding-right: 0;
        }

        &.avatar {
          display: flex;
          align-items: center;
          margin-top: 0;

          @include mixins-breakpoints.breakpoint-tablet {
            margin-top: -4px;
          }

          @include dl-container-breakpoint {
            margin-top: 0;
          }

          ax-ui-avatar {
            margin-right: var(--space-12);
          }
        }
      }

      dd {
        @include mixins-breakpoints.breakpoint-tablet {
          &:not(:last-of-type) {
            margin-bottom: 0;
          }
        }

        @include dl-container-breakpoint {
          &:not(:last-of-type) {
            margin-bottom: var(--space-16);
            padding-bottom: var(--space-16);
          }
        }

        &.avatar {
          @include mixins-breakpoints.breakpoint-tablet {
            padding-left: 0;
          }

          @include dl-container-breakpoint {
            padding-left: 63px;
          }
        }

        &.ax-description-details-list__sub-list {
          > *:not(:last-child) {
            padding-bottom: var(--space-16);
            border-bottom: var(--ax-border-style);
          }
        }
      }
    }

    &.multi-column {
      display: grid;

      @mixin dl-multi-column-container-breakpoint {
        @media (min-width: mixins-breakpoints.$tablet) {
          @container (min-width: 600px) {
            @content
          }
        }
      }

      dl {
        --dd-col-max: minmax(0, 340px);
        @include dl-multi-column-container-breakpoint {
          grid-template-columns: auto var(--dd-col-max) auto 1fr;

          > *:nth-child(4n+1):nth-last-child(-n+4),
          > *:nth-child(4n+1):nth-last-child(-n+4) ~ * {
            border: none;
            padding-bottom: 0;
          }
        }

        .grid-span-2 {
          grid-column: span 2;
        }

        .grid-span-3--desktop-large {
          @include mixins-breakpoints.breakpoint-desktop-large {
            grid-column: span 3;
          }
        }
      }

      &.full-width {
        dl {
          @include mixins-breakpoints.breakpoint-desktop-large-to-super-large {
            grid-template-columns: auto var(--dd-col-max) auto 1fr;

            > *:nth-child(4n+1):nth-last-child(-n+4),
            > *:nth-child(4n+1):nth-last-child(-n+4) ~ * {
              border: none;
              padding-bottom: 0;
            }
          }

          @include mixins-breakpoints.breakpoint-desktop-super-large-to-wide-screen {
            grid-template-columns: auto var(--dd-col-max) auto var(--dd-col-max) auto 1fr;

            > *:nth-child(6n+1):nth-last-child(-n+6),
            > *:nth-child(6n+1):nth-last-child(-n+6) ~ * {
              border: none;
              padding-bottom: 0;
            }
          }

          @include mixins-breakpoints.breakpoint-desktop-wide-screen {
            grid-template-columns: auto var(--dd-col-max) auto var(--dd-col-max) auto var(--dd-col-max) auto 1fr;

            > *:nth-child(8n+1):nth-last-child(-n+8),
            > *:nth-child(8n+1):nth-last-child(-n+8) ~ * {
              border: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__inline-icon {
    margin-top: -6px;
  }

  &.editable {
    dl {
      gap: 0;

      dt, dd {
        padding-bottom: 0 !important;
        border-bottom: 0 !important;
      }

      dt {
        padding-top: 11px;
      }

      dd {
        position: relative;

        ax-ui-button {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:not(:last-of-type) {
          margin-bottom: var(--space-4);

          @include mixins-breakpoints.breakpoint-tablet {
            margin-bottom: 0;
          }
          @include dl-container-breakpoint {
            margin-bottom: 0;
          }
        }

        &:last-child {
          > * {
            border-bottom-color: transparent;
          }
        }

        ax-ui-checkbox, ax-ui-label {
          display: block;
          border-bottom: 1px solid var(--color-neutral-200);
          margin-bottom: 22px;
        }

        ax-ui-checkbox {
          margin-top: -2px;
        }

        ax-ui-label {
          padding-bottom: var(--space-8);
        }
      }

      @media (max-width: mixins-breakpoints.$tablet) {
        dt {
          padding-top: 0;
          padding-bottom: 2px !important;
        }
      }

      @include dl-container-breakpoint {
        dt {
          padding-top: 0;
          padding-bottom: 2px !important;
        }
      }
    }
  }

  &.dd-row-padding {
    padding-top: var(--space-16);

    dt, dd {
      padding-left: var(--space-16);
      padding-bottom: var(--space-16) !important;
    }
  }
}
