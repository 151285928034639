.ax-navigation-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-4);

  &__item {
    display: flex;
    align-items: center;
    padding: var(--space-8);
    border-radius: var(--border-radius-8);
    transition: all 0.25s ease-in-out;
    border: none;
    height: 40px;
    width: 100%;
    background: transparent;
    color: var(--color-neutral-800);

    .label {
      padding-top: 2px;
      transition: all 0.25s ease-in-out;
      line-height: initial;
      white-space: nowrap;
      margin-left: var(--space-8);

      &.hide {
        width: 0;
        opacity: 0;
      }
    }

    @media (hover: hover) {
      text-decoration: none !important;

      &:not(.menu):hover {
        cursor: pointer;
        color: inherit;

        &:not(.active) {
          background: var(--color-primary-50);
          color: inherit;
        }
      }
    }

    &:not(.menu):active {
      background: var(--color-primary-200);
      transition: 0s;
    }

    &.active {
      background: var(--color-primary-100);
      font-family: var(--font-medium);
    }

    &.menu {
      width: fit-content;
    }
  }
}
