/** Breakpoints **/
$mobile-small: 375px;
$mobile-large: 640px;
$tablet: 768px;
$desktop: 1024px;
$desktop-large:  1280px;
$desktop-extra-large:  1440px;
$desktop-super-large:  1920px;
$desktop-wide-screen:  2560px;

@mixin breakpoint-mobile-small {
  @media screen and (min-width: $mobile-small) {
    @content;
  }
}

@mixin breakpoint-mobile-large {
  @media (min-width: $mobile-large) {
    @content;
  }
}

@mixin breakpoint-mobile-large-max {
  @media (max-width: $mobile-large) {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin breakpoint-tablet-max {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin breakpoint-desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin breakpoint-desktop-large {
  @media (min-width: $desktop-large) {
    @content;
  }
}

@mixin breakpoint-desktop-large-to-super-large {
  @media (min-width: $desktop-large) and (max-width: $desktop-super-large) {
    @content;
  }
}

@mixin breakpoint-desktop-large-max {
  @media (max-width: $desktop-large) {
    @content;
  }
}

@mixin breakpoint-desktop-extra-large {
  @media (min-width: $desktop-extra-large) {
    @content;
  }
}

@mixin breakpoint-desktop-super-large {
  @media (min-width: $desktop-super-large) {
    @content;
  }
}

@mixin breakpoint-desktop-super-large-to-wide-screen {
  @media (min-width: $desktop-super-large) and (max-width: $desktop-wide-screen) {
    @content;
  }
}

@mixin breakpoint-desktop-wide-screen {
  @media (min-width: $desktop-wide-screen) {
    @content;
  }
}
