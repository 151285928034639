.fade-in {
  animation: fadeIn 0.3s;
}

.fade-in-forwards {
  animation: fadeIn 0.3s forwards;
}

.fade-in-opacity-only {
  animation: fadeInOpacity 0.3s forwards;
}

.fade-in-opacity-only-no-forwards {
  animation: fadeInOpacity 0.3s;
}

.fade-out-opacity-only {
  animation: fadeOutOpacity 0.3s forwards;
  pointer-events: none;
}

.fade-out {
  animation: fadeOut 0.3s forwards;
}

.slide-in-from-top {
  animation: slideInFromTop 0.3s forwards;
}

.slide-out-to-top {
  animation: slideOutToTop 0.3s forwards;
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-24px);
    display: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOutToTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-24px);
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
