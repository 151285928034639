$animation-speed: 0.15s;

.ax-loading-element {

  &.loading {
    opacity: 1;
    animation: loadingShow $animation-speed ease-in, loadingRotation 0.45s $animation-speed infinite linear;
  }

  &.show {
    opacity: 1;
    animation: loadingShow $animation-speed ease-in;
  }

  &.show-on-init {
    opacity: 1;
  }

  // prevent show animation to be seen on init
  &.hide-on-init {
    opacity: 0;
  }

  &.hide {
    opacity: 0;
    animation: loadingHide $animation-speed ease-out;
  }
}


/* animation keyframes */
@keyframes loadingRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loadingShow {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

