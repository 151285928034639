@use "sass:map";
@use "mixins-breakpoints";

$breakpoints: (
    mobile: (min: 320px, max: 767px),
    tablet: (min: 768px, max: 1023px),
    desktop: (min: 1024px),
    desktop-large: (min: 1280px),
    desktop-super-large: (min: 1920px),
);

$gutter: 24px;

.ax-grid-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-24);
}

.ax-grid-row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-24);
  margin-left: calc(-#{$gutter} / 2);
  margin-right: calc(-#{$gutter} / 2);

  &:not(.ax-grid-row-reversed--desktop), {
    @include mixins-breakpoints.breakpoint-desktop {
      gap: initial;
    }
  }

  &:has(.ax-grid-col-desktop-12) {
    @include mixins-breakpoints.breakpoint-desktop {
      gap: var(--space-24);
    }

    @include mixins-breakpoints.breakpoint-desktop-large {
      gap: initial;
    }
  }

  &-reversed {
    flex-direction: column-reverse;

    &--tablet {
      flex-direction: column-reverse;

      @include mixins-breakpoints.breakpoint-desktop {
        flex-direction: initial;
      }
    }

    &--desktop {
      flex-direction: column-reverse;

      @include mixins-breakpoints.breakpoint-desktop-large {
        gap: initial;
        flex-direction: initial;
      }
    }
  }

  &.vertical-center {
    align-items: center;
  }
}

.ax-grid-col {

  &-horizontal-start {
    display: flex;
    justify-content: flex-start;
  }

  &-horizontal-center {
    display: flex;
    justify-content: center;
  }

  &-horizontal-end {
    display: flex;
    justify-content: flex-end;
  }

  &-vertical-center {
    display: flex;
    align-items: center;
  }
}

.ax-grid-side-nav {
  display: grid;
  grid-template-columns: auto;
  column-gap: var(--layout-content-container-padding);
  row-gap: var(--space-24);

  @include mixins-breakpoints.breakpoint-desktop {
    grid-template-columns: auto 1fr;
    row-gap: unset;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  @include mixins-breakpoints.breakpoint-desktop-super-large {
    column-gap: calc(var(--layout-content-container-padding) * 2);
  }

  &__content {
    height: fit-content;

    @include mixins-breakpoints.breakpoint-desktop {
      padding: var(--layout-content-container-padding) var(--layout-content-container-padding) calc(var(--layout-content-container-padding) * 2) 0;
    }
  }

  .ax-table {
    display: grid;
  }

  @include mixins-breakpoints.breakpoint-desktop {
    grid-template-columns: auto 1fr;
  }
}

// Automate the creation of col- classes
@each $bp, $sizes in $breakpoints {
  @for $i from 1 through 12 {
    .ax-grid-col-#{$bp}-#{$i} {
      box-sizing: border-box;
      padding-left: calc(#{$gutter} / 2);
      padding-right: calc(#{$gutter} / 2);

      & > *:not(:last-child) {
        margin-bottom: $gutter;
      }

      @if map.has-key($sizes, max) {
        @media (min-width: map.get($sizes, min)) and (max-width: map.get($sizes, max)) {
          flex-basis: calc(100% / 12 * #{$i});
          max-width: calc(100% / 12 * #{$i});
        }
      } @else {
        @media (min-width: map.get($sizes, min)) {
          flex-basis: calc(100% / 12 * #{$i});
          max-width: calc(100% / 12 * #{$i});
        }
      }
    }
  }
}
