.ax-badge {
  height: var(--space-16);
  min-width: var(--space-8);
  padding: 3px 6px 2px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: var(--color-primary);
  color: var(--color-neutral-white);
  text-align: center;
  font-family: var(--font-regular);
  font-size: 11px !important;

  &--notification {
    position: absolute;
    right: -8px;
    top: -4px;
    background: var(--color-notifications-background);
  }

  &--neutral {
    background: var(--color-neutral-600);
  }

  &--danger {
    background: var(--color-error-600);
  }
}
