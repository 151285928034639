$animation-speed: 0.25s;

.ax-loading-element {

  &.loading {
    opacity: 1;
    animation: loadingGrow $animation-speed ease-in, loadingRotation 0.5s $animation-speed infinite linear;
  }

  &.show {
    opacity: 1;
    animation: loadingGrow $animation-speed ease-in;
  }

  &.show-on-init {
    opacity: 1;
  }

  // prevent show animation to be seen on init
  &.hide-on-init {
    opacity: 0;
  }

  &.hide {
    opacity: 0;
    animation: loadingShrink $animation-speed ease-out;
  }
}


/* animation keyframes */
@keyframes loadingRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loadingGrow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.4);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes loadingShrink {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

