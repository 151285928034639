@use "mixins-breakpoints";

/*
Fluid Type Scale: https://utopia.fyi/type/calculator?c=320,14,1.125,1920,16,1.125,5,2,&s=0.75|0.5|2|3|4,1.5|2|3|4|6,s-l&g=s,l,xl,12
MIN VIEWPORT: Width 320px, Font size 14px, Type scale 1.125 (Major Second)
MAX VIEWPORT: Width 1920px, Font size 16px, Type scale 1.125 (Major Second)
*/

p, a, ul, li, span, dt, dd {
  line-height: 130%;

  @include mixins-breakpoints.breakpoint-tablet {
    line-height: 140%;
  }

  &, button {
    font-size: clamp(0.875rem, 0.85rem + 0.125vw, 1rem);
  }
}

// input text min size of 16px to guarantee accessibility on mobile
input, textarea, select, .ax-ui-select__custom, .ax-input input, .ax-input textarea {
  font-size: 1rem;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-primary-700);

  b, strong {
    color: var(--color-primary-600);
  }

  &.neutral {
    color: var(--color-text-primary);

    p {
      color: var(--color-text-secondary);
    }
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary-800);
    }
  }

  &:active {
    color: var(--color-primary-900);
  }
}

small, small span, small a, figcaption {
  font-size: var(--font-size-small);
  line-height: 160%;

  @include mixins-breakpoints.breakpoint-tablet {
    line-height: 140%;
  }
}

small {
  &, & a {
    line-height: 140%;
  }
}

b, strong {
  font-family: var(--font-medium);
}

i {
  font-style: italic;
}

em {
  font-style: italic;
}

.color {
  &-light {
    &, * {
      color: var(--color-text-secondary)
    }
  }

  &-dark {
    &, * {
      color: var(--color-text-primary)
    }
  }

  &-green {
    &, * {
      color: var(--color-primary);
    }

    &--100 {
      &, * {
        color: var(--color-primary-100);
      }
    }

    &--600 {
      &, * {
        color: var(--color-primary-600);
      }
    }
  }

  &-white {
    &, * {
      color: var(--color-background);
    }
  }

  &-error {
    &, * {
      color: var(--color-text-error);
    }
  }

  &-unset {
    &, * {
      color: unset;
    }
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25vh;
}

.highlighted {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: var(--color-warning-state-background);
    z-index: -1;
    border-radius: 4px;
  }
}

i[title], p[title], span[title] {
  cursor: help;
}

.text-overflow-ellipsis {
  &, * {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6, caption {
  font-family: var(--font-bold);
  color: var(--color-text-primary);

  &, * {
    line-height: 120%;
  }
}

h1 {
  font-size: clamp(1.5769rem, 1.5319rem + 0.225vw, 1.8019rem);
}

h2 {
  font-size: clamp(1.4019rem, 1.3619rem + 0.2vw, 1.6019rem);
}

h3, caption {
  font-size: clamp(1.2456rem, 1.21rem + 0.1781vw, 1.4238rem);
}

h4 {
  font-size: clamp(1.1075rem, 1.0759rem + 0.1581vw, 1.2656rem);
}

h5 {
  font-size: clamp(0.9844rem, 0.9563rem + 0.1406vw, 1.125rem);
}

.ax-text {
  &-regular {
    font-family: var(--font-regular);
  }

  &-medium {
    font-family: var(--font-medium);
  }

  &-bold {
    font-family: var(--font-bold);
  }

  &-align-left {
    text-align: left;
  }

  &-align-center {
    text-align: center;
  }

  &-align-right {
    text-align: right;
  }

  &-no-wrap {
    white-space: nowrap;
  }

  &-normal-wrap {
    white-space: normal;
  }

  &-preserve-breaks {
    white-space: pre-wrap;
  }


  &-prose {
    *:first-child {
      margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: var(--space-48);
      margin-bottom: var(--space-16);
    }

    p, ul, ol {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    ul {
      &:not(.icon-list) {
        padding-left: 0.5em;

        li {
          position: relative;
          padding-left: 1.4em;
          margin-bottom: var(--space-12);

          &::before {
            content: '\2022';
            font-size: 1.5em;
            position: absolute;
            left: 0;
            top: 0;
            line-height: 1;
          }
        }
      }

      &.icon-list {
        li {
          display: flex;
          gap: var(--space-16);
          margin-bottom: var(--space-24);

          span {
            padding-top: 2px;

            @include mixins-breakpoints.breakpoint-tablet {
              padding-top: 0;
            }
          }
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1.5em;

      li {
        margin-bottom: var(--space-12);
        padding-left: 0.4em;
      }
    }

    figure {
      margin: var(--space-48) 0;

      img {
        width: 100%;
        height: auto;
        border-radius: var(--border-radius-8);
      }

      figcaption {
        margin-top: var(--space-8);
        text-align: right;
        color: var(--color-text-secondary)
      }
    }

    &__small-spacings {
      h1, h2, h3, h4, h5, h6 {
        margin-top: var(--space-16);
      }

      p, ul, ol {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.ax-ui-required-asterisk {
  color: var(--color-text-error);
}

.ax-font-monospace {
  font-family: monospace, Courier;
}

pre, code {
  font-family: Courier, "Courier New", monospace;
}

pre {
  padding: var(--space-24);
  background-color: var(--color-neutral-200);
  display: flex;
}
