.ax-label-badge {
  position: relative;

  &:after {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(3px);
    font-family: 'Material Symbols Rounded';
    font-variation-settings: 'FILL' 1,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
    content: '\e838';
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 100%;
    font-size: 20px;
    background: var(--color-warning-state-background);
    color: var(--color-warning-state-content);
    border: 1px solid var(--color-warning-state-border);
  }

  &.is-team-lead {
    &:after {
      content: '\e838';
    }
  }

  &.has-birthday {
    &:after {
      content: '\ef91';
    }
  }
}
