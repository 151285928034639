.ax-link {

  &--underlined {
    text-decoration: underline;
    text-decoration-color: var(--color-primary);
    text-decoration-thickness: 2px;
    text-underline-offset: 0.5rem;
    font-family: var(--font-medium);
  }

  &--underlined, &--dark, &--dark-all a:not(.ax-ui-button) {
    &, * {
      color: var(--color-text-primary);
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;

        &, * {
          color: var(--color-primary-600);
        }
      }
    }

    &:active {
      &, * {
        color: var(--color-primary-800);
      }
    }

    &.active {
      &, * {
        color: var(--color-primary-600);
      }
    }
  }

  &__animated-icon {
    ax-ui-icon:last-child {
      transition: 0.14s ease-in-out;
      transform: translateX(0);
    }

    @media (hover: hover) {
      &:hover {
        ax-ui-icon:last-child {
          transform: translateX(var(--space-8));
        }
      }
    }
  }
}
