.ax-button-group {
  display: inline-flex;
  list-style-type: none;
  align-items: center;
  gap: 0;
  box-shadow: var(--shadow-button);
  overflow: hidden;

  &.small {
    button {
      height: 32px;
      padding: 0 var(--space-12);
      font-size: var(--font-size-12);
    }
  }

  li:first-child {
    button {
      border-radius: var(--border-radius-8) 0 0 var(--border-radius-8);
    }
  }

  li:last-child {
    button {
      border-right: solid 1px var(--color-neutral-300);
      border-radius: 0 var(--border-radius-8) var(--border-radius-8) 0;
    }
  }

  button {
    height: 42px;
    padding: 0 var(--space-16);
    background: var(--color-neutral-white);
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow-button);
    font-family: var(--font-medium);
    border-top: solid 1px var(--color-neutral-300);
    border-bottom: solid 1px var(--color-neutral-300);
    border-left: solid 1px var(--color-neutral-300);
    border-right: none;
    white-space: nowrap;

    &:hover {
      background: var(--color-neutral-50);
      cursor: pointer;
    }

    &:active, &.active {
      background: var(--color-neutral-50);
      color: var(--color-text-primary);
    }

    &[disabled] {
      pointer-events: none;
      color: var(--color-neutral-400);
      background: var(--color-neutral-100);
      opacity: var(--opacity-disabled);
    }
  }
}
