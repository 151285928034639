.ax-buttons {
  display: flex;
  gap: var(--space-8);

  &--nowrap {
    flex-wrap: nowrap;
  }

  &.justify-end {
    justify-content: flex-end;
  }
}
