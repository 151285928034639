hr {
  width: 100%;
  border: none;
  background-color: var(--color-neutral-200);
  height: 1px;
  min-height: 1px;

  &.full-width {
    width: auto;
    margin-left: calc(var(--space-48) * -1);
    margin-right: calc(var(--space-48) * -1);
  }
}
