@use "../mixins-breakpoints";

.ax-icon {
  &__container {
    background: var(--color-primary);
    border-radius: var(--border-radius-16);
    padding: var(--space-24);
    margin: var(--space-24) 0;
    color: var(--color-neutral-white);

    @include mixins-breakpoints.breakpoint-desktop {
      padding: var(--space-48);
      margin-bottom: var(--space-48);
    }

    &--error {
      background: var(--color-error-state-background);
      border: 1px solid var(--color-error-state-border);
      color: var(--color-error-state-content);
    }
  }
}
