@use "mixins-breakpoints";

/* Margin Classes */
.margin-4 { margin: 4px; }
.margin-8 { margin: 8px; }
.margin-12 { margin: 12px; }
.margin-16 { margin: 16px; }
.margin-24 { margin: 24px; }
.margin-32 { margin: 32px; }
.margin-40 { margin: 40px; }
.margin-48 { margin: 48px; }
.margin-56 { margin: 56px; }
.margin-64 { margin: 64px; }
.margin-72 { margin: 72px; }
.margin-120 { margin: 120px; }
.margin-none { margin: 0 !important; }

.margin-top-2 { margin-top: 2px; }
.margin-top-4 { margin-top: 4px; }
.margin-top-6 { margin-top: 6px; }
.margin-top-8 { margin-top: 8px; }
.margin-top-12 { margin-top: 12px; }
.margin-top-16 { margin-top: 16px; }
.margin-top-24 { margin-top: 24px; }
.margin-top-32 { margin-top: 32px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-48 { margin-top: 48px; }
.margin-top-56 { margin-top: 56px; }
.margin-top-64 { margin-top: 64px; }
.margin-top-72 { margin-top: 72px; }
.margin-top-120 { margin-top: 120px; }

.margin-right-4 { margin-right: 4px; }
.margin-right-8 { margin-right: 8px; }
.margin-right-12 { margin-right: 12px; }
.margin-right-16 { margin-right: 16px; }
.margin-right-24 { margin-right: 24px; }
.margin-right-32 { margin-right: 32px; }
.margin-right-40 { margin-right: 40px; }
.margin-right-48 { margin-right: 48px; }
.margin-right-56 { margin-right: 56px; }
.margin-right-64 { margin-right: 64px; }
.margin-right-72 { margin-right: 72px; }
.margin-right-120 { margin-right: 120px; }

.margin-bottom-4 { margin-bottom: 4px; }
.margin-bottom-8 { margin-bottom: 8px; }
.margin-bottom-12 { margin-bottom: 12px; }
.margin-bottom-16 { margin-bottom: 16px; }
.margin-bottom-24 { margin-bottom: 24px; }
.margin-bottom-32 { margin-bottom: 32px; }
.margin-bottom-40 { margin-bottom: 40px; }
.margin-bottom-48 { margin-bottom: 48px; }
.margin-bottom-56 { margin-bottom: 56px; }
.margin-bottom-64 { margin-bottom: 64px; }
.margin-bottom-72 { margin-bottom: 72px; }
.margin-bottom-120 { margin-bottom: 120px; }

.margin-left-4 { margin-left: 4px; }
.margin-left-8 { margin-left: 8px; }
.margin-left-12 { margin-left: 12px; }
.margin-left-16 { margin-left: 16px; }
.margin-left-24 { margin-left: 24px; }
.margin-left-32 { margin-left: 32px; }
.margin-left-40 { margin-left: 40px; }
.margin-left-48 { margin-left: 48px; }
.margin-left-56 { margin-left: 56px; }
.margin-left-64 { margin-left: 64px; }
.margin-left-72 { margin-left: 72px; }
.margin-left-120 { margin-left: 120px; }

.margin-horizontal-4 { margin-left: 4px; margin-right: 4px; }
.margin-horizontal-8 { margin-left: 8px; margin-right: 8px; }
.margin-horizontal-12 { margin-left: 12px; margin-right: 12px; }
.margin-horizontal-16 { margin-left: 16px; margin-right: 16px; }
.margin-horizontal-24 { margin-left: 24px; margin-right: 24px; }
.margin-horizontal-32 { margin-left: 32px; margin-right: 32px; }
.margin-horizontal-40 { margin-left: 40px; margin-right: 40px; }
.margin-horizontal-48 { margin-left: 48px; margin-right: 48px; }
.margin-horizontal-56 { margin-left: 56px; margin-right: 56px; }
.margin-horizontal-64 { margin-left: 64px; margin-right: 64px; }
.margin-horizontal-72 { margin-left: 72px; margin-right: 72px; }
.margin-horizontal-120 { margin-left: 120px; margin-right: 120px; }
.margin-horizontal-auto { margin-left: auto; margin-right: auto; }

.margin-vertical-4 { margin-top: 4px; margin-bottom: 4px; }
.margin-vertical-8 { margin-top: 8px; margin-bottom: 8px; }
.margin-vertical-12 { margin-top: 12px; margin-bottom: 12px; }
.margin-vertical-16 { margin-top: 16px; margin-bottom: 16px; }
.margin-vertical-24 { margin-top: 24px; margin-bottom: 24px; }
.margin-vertical-32 { margin-top: 32px; margin-bottom: 32px; }
.margin-vertical-40 { margin-top: 40px; margin-bottom: 40px; }
.margin-vertical-48 { margin-top: 48px; margin-bottom: 48px; }
.margin-vertical-56 { margin-top: 56px; margin-bottom: 56px; }
.margin-vertical-64 { margin-top: 64px; margin-bottom: 64px; }
.margin-vertical-72 { margin-top: 72px; margin-bottom: 72px; }
.margin-vertical-120 { margin-top: 120px; margin-bottom: 120px; }

/* Padding Classes */
.padding-4 { padding: 4px; }
.padding-8 { padding: 8px; }
.padding-12 { padding: 12px; }
.padding-16 { padding: 16px; }
.padding-24 { padding: 24px; }
.padding-32 { padding: 32px; }
.padding-40 { padding: 40px; }
.padding-48 { padding: 48px; }
.padding-56 { padding: 56px; }
.padding-64 { padding: 64px; }
.padding-72 { padding: 72px; }
.padding-120 { padding: 120px; }
.padding-none { padding: 0 !important; }

.padding-top-4 { padding-top: 4px; }
.padding-top-8 { padding-top: 8px; }
.padding-top-12 { padding-top: 12px; }
.padding-top-16 { padding-top: 16px; }
.padding-top-24 { padding-top: 24px; }
.padding-top-32 { padding-top: 32px; }
.padding-top-40 { padding-top: 40px; }
.padding-top-48 { padding-top: 48px; }
.padding-top-56 { padding-top: 56px; }
.padding-top-64 { padding-top: 64px; }
.padding-top-72 { padding-top: 72px; }
.padding-top-120 { padding-top: 120px; }

.padding-right-4 { padding-right: 4px; }
.padding-right-8 { padding-right: 8px; }
.padding-right-12 { padding-right: 12px; }
.padding-right-16 { padding-right: 16px; }
.padding-right-24 { padding-right: 24px; }
.padding-right-32 { padding-right: 32px; }
.padding-right-40 { padding-right: 40px; }
.padding-right-48 { padding-right: 48px; }
.padding-right-56 { padding-right: 56px; }
.padding-right-64 { padding-right: 64px; }
.padding-right-72 { padding-right: 72px; }
.padding-right-120 { padding-right: 120px; }

.padding-bottom-4 { padding-bottom: 4px; }
.padding-bottom-8 { padding-bottom: 8px; }
.padding-bottom-12 { padding-bottom: 12px; }
.padding-bottom-16 { padding-bottom: 16px; }
.padding-bottom-24 { padding-bottom: 24px; }
.padding-bottom-32 { padding-bottom: 32px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-48 { padding-bottom: 48px; }
.padding-bottom-56 { padding-bottom: 56px; }
.padding-bottom-64 { padding-bottom: 64px; }
.padding-bottom-72 { padding-bottom: 72px; }
.padding-bottom-120 { padding-bottom: 120px; }

.padding-left-4 { padding-left: 4px; }
.padding-left-8 { padding-left: 8px; }
.padding-left-12 { padding-left: 12px; }
.padding-left-16 { padding-left: 16px; }
.padding-left-24 { padding-left: 24px; }
.padding-left-32 { padding-left: 32px; }
.padding-left-40 { padding-left: 40px; }
.padding-left-48 { padding-left: 48px; }
.padding-left-56 { padding-left: 56px; }
.padding-left-64 { padding-left: 64px; }
.padding-left-72 { padding-left: 72px; }
.padding-left-120 { padding-left: 120px; }

.padding-horizontal-4 { padding-left: 4px; padding-right: 4px; }
.padding-horizontal-8 { padding-left: 8px; padding-right: 8px; }
.padding-horizontal-12 { padding-left: 12px; padding-right: 12px; }
.padding-horizontal-16 { padding-left: 16px; padding-right: 16px; }
.padding-horizontal-24 { padding-left: 24px; padding-right: 24px; }
.padding-horizontal-32 { padding-left: 32px; padding-right: 32px; }
.padding-horizontal-40 { padding-left: 40px; padding-right: 40px; }
.padding-horizontal-48 { padding-left: 48px; padding-right: 48px; }
.padding-horizontal-56 { padding-left: 56px; padding-right: 56px; }
.padding-horizontal-64 { padding-left: 64px; padding-right: 64px; }
.padding-horizontal-72 { padding-left: 72px; padding-right: 72px; }
.padding-horizontal-120 { padding-left: 120px; padding-right: 120px; }

.padding-vertical-4 { padding-top: 4px; padding-bottom: 4px; }
.padding-vertical-8 { padding-top: 8px; padding-bottom: 8px; }
.padding-vertical-12 { padding-top: 12px; padding-bottom: 12px; }
.padding-vertical-16 { padding-top: 16px; padding-bottom: 16px; }
.padding-vertical-24 { padding-top: 24px; padding-bottom: 24px; }
.padding-vertical-32 { padding-top: 32px; padding-bottom: 32px; }
.padding-vertical-40 { padding-top: 40px; padding-bottom: 40px; }
.padding-vertical-48 { padding-top: 48px; padding-bottom: 48px; }
.padding-vertical-56 { padding-top: 56px; padding-bottom: 56px; }
.padding-vertical-64 { padding-top: 64px; padding-bottom: 64px; }
.padding-vertical-72 { padding-top: 72px; padding-bottom: 72px; }
.padding-vertical-120 { padding-top: 120px; padding-bottom: 120px; }

/* Border Radius Classes */
.border-radius-4 { border-radius: 4px; }
.border-radius-8 { border-radius: 8px; }
.border-radius-12 { border-radius: 12px; }
.border-radius-16 { border-radius: 16px; }
.border-radius-24 { border-radius: 24px; }
.border-radius-32 { border-radius: 32px; }
.border-radius-40 { border-radius: 40px; }
.border-radius-48 { border-radius: 48px; }
.border-radius-56 { border-radius: 56px; }
.border-radius-64 { border-radius: 64px; }
.border-radius-72 { border-radius: 72px; }
.border-radius-120 { border-radius: 120px; }

/* Border Classes */
.border {
  border: var(--ax-border-style);

  &-none { border: none !important; }
  &-top { border-top: var(--ax-border-style); }
  &-right { border-right: var(--ax-border-style); }
  &-bottom { border-bottom: var(--ax-border-style); }
  &-left { border-left: var(--ax-border-style); }
}

/* Width */
.width {
  &-50 {
    min-width: 0;
    width: 50%;
  }

  &-100 {
    min-width: 0;
    width: 100%;
  }

  &-fit-content {
    width: fit-content;
  }
}

/* max-width sizes for layout */
@mixin container-padding-responsive {
  padding-left: var(--space-16);
  padding-right: var(--space-16);
  margin-left: auto;
  margin-right: auto;

  @include mixins-breakpoints.breakpoint-mobile-small {
    padding-left: var(--space-24);
    padding-right: var(--space-24);
  }
}

.width-320 { width: 320px; }
.width-384 { width: 384px; }
.width-480 { width: 480px; }
.width-560 { width: 560px; }
.width-640 { width: 640px; }
.width-768 { width: 768px; }
.width-896 { width: 896px; }
.width-1024 { width: 1024px; }
.width-1280 { width: 1280px; }
.width-1440 { width: 1440px; }
.width-1600 { width: 1600px; }
.width-1920 { width: 1920px; }

.max-width-320 { max-width: 320px; }
.max-width-384 { max-width: 384px; }
.max-width-480 { max-width: 480px; }
.max-width-560 { max-width: 560px; }
.max-width-640 { max-width: 640px; }
.max-width-768 { max-width: 768px; }
.max-width-896 { max-width: 896px; }
.max-width-1024 { max-width: 1024px; }
.max-width-1280 { max-width: 1280px; }
.max-width-1440 { max-width: 1440px; }
.max-width-1600 { max-width: 1600px; }
.max-width-1920 { max-width: 1920px; }

.container-max-w-320 {
  max-width: 320px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-384 {
  max-width: 384px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-480 {
  max-width: 480px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-560 {
  max-width: 560px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-640 {
  max-width: 640px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-768 {
  max-width: 768px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-896 {
  max-width: 896px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-1024 {
  max-width: 1024px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-1280 {
  max-width: 1280px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-1440 {
  max-width: 1440px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-1600 {
  max-width: 1600px;
  width: 100%;
  @include container-padding-responsive;
}

.container-max-w-1920 {
  max-width: 1920px;
  width: 100%;
  @include container-padding-responsive;
}

.height {
  &-100 {
    min-height: 0;
    height: 100%;
  }

  &-fit-content {
    height: fit-content;
  }
}
