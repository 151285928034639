@font-face {
  font-family: "Gotham Narrow Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/GothamNarrow-Book.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "Gotham Narrow Light";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/GothamNarrow-Light.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "Gotham Narrow Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/GothamNarrow-Medium.otf") format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "Gotham Narrow Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/GothamNarrow-Bold.otf") format('opentype');
  font-display: swap;
}
