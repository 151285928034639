.ax-dialog-overlay {
  background: var(--color-neutral-white);
  border-radius: var(--border-radius-12);
  border: var(--ax-border-style);
  box-shadow: var(--shadow-card-hover);
  padding: var(--space-24);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 var(--space-24) var(--space-24);
  pointer-events: auto;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: var(--space-16);
    position: relative;

    &-content {
      padding-right: var(--space-40);
    }

    .close-button {
      position: absolute;
      top: -12px;
      right: -12px;
      padding: var(--space-8);
      border-radius: 100%;
      display: block;
      height: fit-content;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          background: var(--color-neutral-100);
        }
      }

      &:active {
        background: var(--color-neutral-200);
      }
    }
  }

  &__container {
    position: fixed;
    max-height: calc(100vh - var(--space-24));
    z-index: var(--z-index-dialogs);
    top: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    padding-top: var(--space-24);

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &.centered {
      left: 0;
      display: flex;
      align-items: center;
    }
  }
}
