@use "../mixins-breakpoints";

.ax-table-filters {
  display: flex;
  flex-direction: column;
  gap: var(--space-24);
  margin-bottom: var(--space-16);

  @include mixins-breakpoints.breakpoint-desktop {
    flex-direction: row;
    justify-content: space-between;
  }

  &__left,
  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);

    @include mixins-breakpoints.breakpoint-desktop {
      flex-direction: row;
      align-items: flex-end;
    }

    &.full-width {
      flex: 10;
    }
  }

  &__left {
    @include mixins-breakpoints.breakpoint-desktop {
      min-width: 320px;
      flex: 1;
    }
  }

  &__right {
    min-width: 0;

    @include mixins-breakpoints.breakpoint-desktop {
      flex: 3;
      justify-content: flex-end;
    }

      > .flex {
      min-width: 0;
      max-width: 100%;
    }
  }
}